






























































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { EventBus } from '@/utils/eventBus'
import { getPosition, getWidth } from '@/utils/dom'
@Component({
  components: {
    FeatureImg
  }
})
export default class NumberPopup extends Vue {
  @Prop({ required: true }) realtimeData: any[]

  @Ref() timeLine?: HTMLDivElement

  isShowPopup = false
  left = false
  right = false
  personList: any[] = []
  statistic: AnyObj = {
    gender: {
      female: 0,
      male: 0
    },
    age: {
      adult: 0,
      child: 0,
      midlife: 0,
      old: 0,
      teen: 0,
      youth: 0
    },
    total: 0,
    tags: {
      vip: 0,
      white: 0,
      black: 0,
      strange: 0
    }
  }
  curPerson: AnyObj | null = null
  count = 0
  areaId = ''
  flag = true
  first = true

  deactivated() {
    document.removeEventListener('click', this.hiddenNode)
  }

  activated() {
    document.addEventListener('click', this.hiddenNode)
  }

  mounted() {
    document.addEventListener('click', this.hiddenNode)
  }

  personClick(obj: AnyObj) {
    obj.id = obj.personId
    this.curPerson = { ...obj }
    EventBus.$emit('personDetail', this.curPerson)
  }

  hiddenNode(e: Event) {
    e.stopPropagation()
    const node = this.$refs.popoverNode as Element
    if (!node) return
    if (!node.contains(e.target as Element) && !this.flag) {
      this.isShowPopup = false
    }
    this.flag = false
  }

  getAreaNum(areaId: string) {
    return this.realtimeData.filter(i => i.areaId === areaId)
  }

  throttle(fn: any, delay: number) {
    return (() => {
      if (this.first) {
        this.first = false
        fn.call(this, fn, delay)
        setTimeout(() => {
          this.first = true
        }, delay)
      }
    })()
  }

  async open(val: AnyObj) {
    this.personList = []
    this.areaId = val.target.areaId
    const num = this.getAreaNum(val.target.areaId)[0]
    if (num === undefined || num.tags === undefined) return
    this.statistic = num
    this.isShowPopup = true
    this.flag = true
    this.throttle(this.getPersonList, 100)
    this.$nextTick(() => {
      const node = this.$refs.popoverNode as HTMLElement
      node.style.left = val.domEvent.offsetX - 183 + 'px'
      node.style.top = val.domEvent.offsetY - 248 + 'px'
    })
  }

  async getPersonList() {
    const opt = {
      params: {
        limit: 50,
        offset: 0,
        areaId: this.areaId
      }
    }
    const resp = await this.$api.v2.realtimePerson(opt)
    if (resp.data && resp.data.data) {
      this.personList = resp.data.data
      this.count = resp.data.count
    }
    this.$nextTick(() => {
      if (this.personList.length > 0) {
        if (this.timeLine) {
          const width = this.computedWidth()
          this.timeLine.style.width = width + 'px'
          this.isLight()
        }
      }
    })
  }

  computedWidth() {
    const m = this.personList.length
    return m * (54 + 10)
  }

  isLight() {
    const timeLine = this.$refs.timeLine as HTMLDivElement
    if (!timeLine) return
    const width = getWidth(timeLine)
    const wrap = timeLine.parentElement
    const wrapWidth = getWidth(wrap)
    const canLeft = width - wrapWidth
    if (canLeft <= 0) {
      this.left = false
      this.right = false
    } else {
      this.left = false
      this.right = true
    }
  }

  async move(rate: number) {
    if ((rate === 1 && !this.left) || (rate === -1 && !this.right)) return
    const timeLine = this.$refs.timeLine as HTMLDivElement
    const left = getPosition(timeLine).left
    const width = getWidth(timeLine)
    const wrap = timeLine.parentElement
    const wrapWidth = getWidth(wrap)
    const canLeft = width - wrapWidth
    if (canLeft < 0) return // 不能移动
    let moved = left + rate * wrapWidth - 23
    if (moved >= 0) {
      moved = 0 // 点击左侧按钮直到不能移动的时候，此时左侧按钮不亮
      this.left = false
      this.right = true
    } else if (-moved >= canLeft) {
      // 点击右侧按钮直到不能移动的时候，此时右侧按钮不亮
      moved = -canLeft
      this.right = false
      this.left = true
    } else {
      this.right = true
      this.left = true
    }
    timeLine.style.left = `${moved}px`
    timeLine.animate([{ left: `${left}px` }, { left: `${moved}px` }], 700)
  }
}
