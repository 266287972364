<script>
//import DGMap from '../fengmap/map.js';
//import fengmap from './fengmap/fengmap.min-v2.3.9'
import NumberPopup from '@/components/dashboard/NumberPopup'
import { Deferred, getHeight } from '@/utils/dom'
import { themeName } from '@/libs/theme/index.ts'
import DGMap from 'map'
let previous = 0
let mapChanged = false
let changeTimer = null
export default {
  data() {
    return {
      cacheTrip: null,
      currentGroup: 0,
      mapReady: new Deferred(),
      scale: '',
      children: [],
      dgmap: null,
      isShowNode: false,
      realtimeData: [],
      isSuccessLoad: false,
      timer: null,
      bloomsEffects: true,
      background: null,
      s3Endpoint: ''
    }
  },
  components: {
    NumberPopup
  },
  props: {
    trip: {
      required: false
    },
    navImg: {
      required: false
    },
    heating: {
      required: false
    },
    groupLine: {
      required: false
    },
    residenceTime: {
      required: false
    },
    domPosition: {
      required: false
    },
    mapCode: {
      required: true
    },
    groupSpace: {
      required: false
    },
    ViewMode: {
      required: false
    },
    isDrawPoint: {
      type: Boolean,
      default: false,
      required: false
    },
    isShowViewModeBtn: {
      type: Boolean,
      default: true,
      required: false
    },
    changeGroup: {
      // 自定义按钮后切换楼层
      type: Number,
      required: false
    },
    isPopup: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowlegend: {
      type: Boolean,
      required: false,
      default: false
    },
    isAvailablelegend: {
      type: Boolean,
      required: false,
      default: true
    },
    firstLoad: {
      type: Boolean,
      required: false,
      default: true
    },
    isRotate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  async mounted() {
    if (!this.isSuccessLoad && (this.mapCode === 'index' || this.mapCode === 'autoRotate')) {
      this.$refs.fengMap.innerHTML = ''
      await this.getMapInfo()
    }
  },
  async activated() {
    if (!this.isSuccessLoad) {
      this.$refs.fengMap.innerHTML = ''
      await this.getMapInfo()
    }
    if (this.isSuccessLoad && this.mapCode === 'home') {
      if (this.timer) clearTimeout(this.timer)
      this.dgmap.updateMap({
        mapCode: this.mapCode,
        type: 'letUpdate',
        value: true
      })
    }
  },
  deactivated() {
    // window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.cacheTrip = null
    if (this.isSuccessLoad && this.mapCode === 'home') {
      this.timer = setTimeout(() => {
        this.dgmap.updateMap({
          mapCode: this.mapCode,
          type: 'letUpdate',
          value: false
        })
      }, 2000)
    }
  },
  beforeDestroy() {
    if (this.mapCode === 'index' && this.$store.state.dashboard.showDetailDialog) {
      this.clickClose()
    }
  },
  methods: {
    async getMapInfo() {
      const resp = this.$store.state.config
      if (Object.keys(resp).length > 0) {
        this.children = resp.children
        this.s3Endpoint = resp.s3Endpoint
        this.init(resp.map)
      }
    },
    init(info) {
      let options = {}
      if (info.target !== undefined && info.target !== null) {
        const target = info.target
        if (target.indexOf(',') < 0) {
          options = Object.assign(options, { rotation: Number(info.target) })
        } else {
          options = Object.assign(options, { rotation: Number(target.split(',')[0]) })
          options = Object.assign(options, { tiltAngle: Number(target.split(',')[1]) })
        }
      }
      if (info.position !== undefined && info.position !== null) {
        const pos = info.position
        const center = {
          x: Number(pos.split(',')[0]),
          y: Number(pos.split(',')[1])
        }
        options = Object.assign(options, { center })
      }
      if (info.scale !== undefined && info.scale !== null) {
        options = Object.assign(options, { mapZoom: info.scale })
      }
      if (info.groupSpace !== undefined && info.groupSpace !== null) {
        options = Object.assign(options, { floorSpace: info.groupSpace })
      }
      if (info.avoid !== undefined) {
        const avoid = info.avoid ? true : false
        options = Object.assign(options, { avoid })
      }

      if (themeName === 'dark') {
        this.bloomsEffects = true
        this.background = 'home-bg.png'
      } else {
        this.bloomsEffects = false
        this.background = null
      }
      const viewModeMap = {
        '3d': fengmap.FMViewMode.MODE_3D,
        '2d': fengmap.FMViewMode.MODE_2D
      }
      const defaultOpt = {
        domPosition: this.domPosition, // 楼层控件和2d,3d切换按钮位置,
        isCustomGroupBtn: this.changeGroup !== undefined, // 是否自定义按钮
        viewMode:
          this.ViewMode === undefined ? fengmap.FMViewMode.MODE_3D : viewModeMap[this.ViewMode],
        isDrawPoint: this.isDrawPoint,
        isShowViewModeBtn: this.isShowViewModeBtn,
        isShowlegend: this.isShowlegend, // 是否显示地图图例
        isAvailablelegend: this.isAvailablelegend, // 地图图例是否可用（地图编辑的可视化配置地图图例不可点击）
        background: this.background,
        isRotate: this.isRotate, // 地图是否自动旋转
        bloomsEffects: this.bloomsEffects // 地图热力是否发光
      }
      const opt = Object.assign(defaultOpt, options)
      const tenantId = this.$store.state.tenantId
      this.dgmap = new DGMap(
        {
          initMap: info,
          container: 'feng-map',
          options: opt,
          mapCode: this.mapCode,
          mapPath: `${this.s3Endpoint}/maps/${tenantId}/`,
          path: '/static/fengmap/img',
          area: this.children
        },
        val => {
          this.isSuccessLoad = true
          this.$emit('loadComplete', val)
          let maxFps = 60
          if (this.mapCode === 'index') maxFps = 20
          if (['edit', 'config', 'data'].includes(this.mapCode)) maxFps = 5
          if (this.mapCode === 'home') {
            maxFps = 0.5
            this.mapViewChanged(val.map)
          }
          this.dgmap.updateMap({
            mapCode: this.mapCode,
            type: 'maxFps',
            value: maxFps
          })
          this.mapReady.resolve()
          if (this.isShowlegend && (this.mapCode === 'home' || this.mapCode === 'config')) {
            const legendCon = document.getElementsByClassName('legend-con')[0]
            legendCon.style.marginLeft = '-140px'
          }
          if (this.isRotate) {
            const fmControlGroups = document.getElementsByClassName('fm-control-groups')[0]
            const height = getHeight(fmControlGroups)
            this.$nextTick(() => {
              this.$refs.restoreNode.style.bottom = height + 60 + 'px'
            })
          }
          this.dgmap.getClickArea(this.isPopup, val => {
            //点击首页实时热力区域
            if (this.isPopup) {
              this.isShowNode = true
              this.$nextTick(() => {
                this.$refs.popupNode.open(val)
              })
            }
          })
        }
      )
    },
    mapViewChanged(map) {
      map.on('mapViewChanged', () => {
        const now = Date.now()
        if (now - previous > 1000) {
          previous = now
          if (!mapChanged) {
            map.maxFps = 30
            mapChanged = true
          }
          if (changeTimer) clearTimeout(changeTimer)
          changeTimer = setTimeout(() => {
            map.maxFps = 0.5
            mapChanged = false
            previous = 0
            changeTimer = null
          }, 2000)
        }
      })
    },
    // beforeunloadFn(e) {
    //     sessionStorage.removeItem('heating');
    // },
    async clickClose() {
      // 关闭弹窗时先判断有无导航，如果有导航都stop
      this.$store.state.dashboard.showDetailDialog = false
      await this.mapReady
      this.dgmap.stopSimulate()
    },
    async getNavImg() {
      const { data } = await this.$api.base.formatImage(this.navImg)

      if (data.data) {
        return 'data:img/jpg;base64,' + data.data.base64
      }
    },
    getMapSet() {
      // 获取地图的scale，rotateAngle，tiltAngle，position
      if (this.dgmap) this.dgmap.getMapSet(this.mapCode)
    },
    drawArea(value, type) {
      this.dgmap.startDrawArea(
        {
          mapCode: this.mapCode,
          value: value,
          type: type,
          callback: (val, groupId) => {
            //绘制回调
            this.$emit('drawRealPoint', val, groupId)
          }
        },
        (val, name) => {
          //结束绘制回调
          this.$emit('drawPoints', val, name)
        }
      )
    },
    stepBackDraw() {
      this.dgmap.stepBackDraw({
        mapCode: this.mapCode
      })
    },
    updateAreaName(id, name) {
      this.dgmap.updateAreasName({
        mapCode: this.mapCode,
        id: id,
        name: name
      })
    },
    removeArea(id) {
      this.dgmap.removeAreas({
        mapCode: this.mapCode,
        id: id
      })
    },
    setHighlight(id, mapLabel) {
      this.dgmap.highlightArea({
        mapCode: this.mapCode,
        id: id,
        mapLabel: mapLabel
      })
    },
    async changeTab(title) {
      await this.mapReady
      this.dgmap.changeLegend({
        mapCode: this.mapCode,
        legendTitle: title
      })
    },
    restore() {
      this.dgmap.restore({
        mapCode: this.mapCode
      })
    },
    dispose() {
      this.dgmap.dispose({
        mapCode: this.mapCode
      })
    }
  },
  watch: {
    trip: {
      async handler(trip) {
        if (trip && trip.length) {
          const navImg = await this.getNavImg()
          const moveLine = {
            //是否要导航和导航的头像
            isNav: true, //必填
            navImg: navImg, //不必填
            // navImg:'/static/fengmap/img/pointer.png', //不必填
            isFollowPosition: true, //默认true，跟随定位
            speed: 6
          }
          await this.mapReady
          this.dgmap.drawMoveingLine({
            tripsMap: trip,
            mapCode: this.mapCode,
            moveLine: moveLine, //非必填
            areaPoints: val => {
              // 非必填,导航路过的区域id
              if (val.unMarkArea) {
                const name = Array.from(new Set(val.unMarkArea.map(i => i.name)))
                name.forEach(obj => {
                  setTimeout(() => {
                    this.$message({
                      message: obj + '未在地图上标点',
                      type: 'error'
                    })
                  }, 0)
                })
              }
            }
          })
        }
      },
      immediate: true
    },
    domPosition: {
      handler(val) {
        if (
          this.$refs.fengMap &&
          this.$refs.fengMap.childNodes &&
          this.$refs.fengMap.childNodes[1]
        ) {
          if (this.$refs.fengMap.childNodes[1].childNodes[0]) {
            this.$refs.fengMap.childNodes[1].childNodes[0].style.right = val.button.x + 'px'
          }
          if (this.$refs.fengMap.childNodes[3]) {
            this.$refs.fengMap.childNodes[3].style.right = val.groupControl.x + 'px'
          }
        }
      },
      deep: true,
      immediate: true
    },
    async heating(arr) {
      await this.mapReady
      const style = {
        isShow: true,
        unit: '人',
        type: 'capacity'
      }
      this.dgmap.drawHeatMap({
        heatMap: arr,
        mapCode: this.mapCode,
        style: style,
        legendTitle: '区域人数/区域容量'
      })
      this.realtimeData = arr
    },
    async residenceTime(arr) {
      await this.mapReady
      const style = {
        isShow: true,
        unit: '分钟',
        type: 'max'
      }
      this.dgmap.drawHeatMap({
        heatMap: arr,
        mapCode: this.mapCode,
        style: style,
        legendTitle: '区域平均停留时间/所有区域平均停留时间最大值'
      })
    },
    async groupLine(arr) {
      await this.mapReady
      this.dgmap.drawGroupLine({
        groupLine: arr,
        mapCode: this.mapCode
      })
    },
    async groupSpace(num) {
      await this.mapReady
      this.dgmap.setGroupSpace({
        mapCode: this.mapCode,
        value: num
      })
    },
    async changeGroup(num) {
      this.dgmap.changeGroupId({
        mapCode: this.mapCode,
        value: num
      })
    }
  }
}
</script>
<template>
  <div class="feng-map-wrap">
    <div ref="fengMap" id="feng-map"></div>
    <number-popup ref="popupNode" v-if="isShowNode" :realtimeData="realtimeData"></number-popup>
    <el-tooltip
      content="恢复初始设置"
      v-if="isRotate && isSuccessLoad"
      placement="top"
      popper-class="restore-tooltip"
    >
      <div class="restore" @click="restore" ref="restoreNode">
        <img src="@/assets/images/dashboard/restore-setting.png" />
      </div>
    </el-tooltip>
  </div>
</template>
<style lang="scss" scoped>
@import '~@/assets/css/fengmap-toolbar.css';
::v-deep .el-tooltip__popper.restore-tooltip {
  background: var(--text-color-secondary);
  box-shadow: 0 0 4px 0 var(--background-color-popper);
  font-size: 12px;
  color: var(--background-color-menu);
  .popper__arrow:after,
  .popper__arrow {
    border-top-color: var(--text-color-secondary);
  }
}

::v-deep .legend-con {
  color: #dbdce7;
}

.feng-map-wrap {
  position: relative;
  height: 100%;
  #feng-map {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    /* opacity: 0; */
    transition: opacity 0.3s;
    outline: none;
  }
  .mapBtns {
    transition: right 0.5s linear;
    -webkit-transition: right 0.5s linear; /* Safari */
  }
  .fm-control-groups {
    transition: right 0.5s linear;
    -webkit-transition: right 0.5s linear; /* Safari */
  }
  .restore {
    width: 42px;
    height: 42px;
    border-radius: 2px;
    border: 1px solid $grey-color;
    position: absolute;
    bottom: 268px;
    right: 40px;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
  }
}
</style>
